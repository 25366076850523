import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('../views/layout/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/home/HomeView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import( '../views/about/index.vue')
      },
      {
        path: '/blindbox',
        component: () => import('@/views/blindbox/index.vue')
      },
      {
        path: '/blindboxDetail',
        name: 'blindboxDetail',
        component: () => import('@/views/blindbox/detail/index.vue')
      },
      {
        path: '/news',
        component: () => import('../views/news/index.vue')
      },
      {
        path: '/newsDetail',
        component: () => import('../views/news/detail.vue')
      },
      {
        path: '/realtime',
        component: () => import('../views/realtime/index.vue')
      },
      {
        path: '/lotteryDetail',
        name: 'lotteryDetail',
        component: () => import('@/views/lottery/detail.vue')
      },
      {
        path:'/commbox',
        component: () => import('@/views/commbox/index.vue')
      },
      {
        path:'/address',
        component: () => import('@/views/address/index.vue')
      },
      {
        path:'/userCenter',
        component: () => import('@/views/userCenter/index.vue')
      }
      
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/login/Register.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
