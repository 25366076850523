//封装的请求方法

import base, { host } from './base.js'
// console.log(base,host)

//二次封装的axios（自己封装 1.项目中封装好了 请使用项目中 2.未封装-可以封装-百度一个）
import axios from '@/utils/request.js'

//定义方法
export default {
  /*
    热门城市
  */
  getHotCity() {
    return axios.get(base.hotcity)
  },
  getHomeData(page){
    return axios.get(base.homeData,{
      params:{
        pageNum:page,
        pageSize:8
      }
    })
  },
  /*
    搜索框内容
  */
  getSearch(params) {
    return axios.get(base.search, {
      params: params
    })
  },
  /*
   *  今日推荐
   */
  getTodyInfo() {
    return axios.get(base.todyInfo)
  },
  getLotteryDetail(id) {
    return axios.get(base.lotteryDetail + id)
  },
  login(data) {
   return axios.post(base.login, data)
  },
  register(data) {
    return axios.post(base.register, data)
  },
  sendCode(data) {
    return axios.post(base.sendCode, data)
  },
  logout() {
    return axios.get(base.logout)
  },
  getTypeDetail(id){
    return axios.get(base.typeDetail,{
      params:{
        id:id
      }
    })
  },
  getTypeList(id){
    return axios.get(base.typeList,{
      
    }
    )
  },
  getImagesByType(id){
    return axios.get(base.commodityImageList,{
      params:{
        id:id
      }
    })
  },
  getTotalCommodityList(typeId){
    return axios.get(base.getTotalCommodityList,{
      params:{
        typeId:typeId
      }
    })
  },
  getCommodityList(id){
    return axios.get(base.commodityListByType,{
      params:{id:id}
    }
    )
  },
  getCartInfo(id){
    return axios.get(base.cartInfo,{
      params:{
        id:id
      }
    })
  },
  lotteryNow(codes,typeId){
    return axios.post(base.lotteryNow,{codes:codes,typeId:typeId})

  },
  getAbout(){
    return axios.get(base.about)
  }

}
