import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

//导入样式初始化
import 'normalize.css'
import '@/assets/css/base.css'

//导入api接口
// import '@/api/index.js'
import api from '@/api/index.js'
Vue.prototype.$api = api


//swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

