//公共配置地址接口

//360接口：https://bang.360.cn/aj/getcitycode
//穷游接口：https://www.qyer.com
const base = {
  host: 'https://bang.360.cn',
  hotcity: '/api/aj/getcitycode', //热门城市
  homeData: '/api/home/index',
  search: '/foo/qcross/place/public.php?action=placesearch&client=web', //搜索框
  todyInfo: '/foo/qcross/home/index.php?action=recommend', //今日推荐
  sendCode: '/api/sendCode',
  login:'/api/login',
  register: '/api/register',
  logout:'/api/logout',
  typeDetail: '/api/type/detail',
  typeList: '/api/type/commodityTypes',
  commodityImageList: '/api/commodity/image/list',
  commodityListByType: '/api/commodity/listByType',
  getTotalCommodityList: '/api/commodity/getTotalCommodityList',
  cartInfo: '/api/cart/info',
  lotteryNow: '/api/lottery/pickNow',
  about: '/api/privacy/about'
}
export default base

export const host = 'https://bang.360.cn'
